<template>
  <div class="hold-transition">
    <div id="accordion">
      <div class="card card-gray-dark mb-1">
        <div class="card-header">
          <a
            data-toggle="collapse"
            href="#collapse"
            aria-expanded="false"
            class="d-block w-100 collapsed"
            ><h4 class="card-title w-100">
              <i class="fas fa-history mr-2"></i> Histórico de tarifas
            </h4></a
          >
        </div>
        <div id="collapse" data-parent="#accordion" class="collapse">
          <div class="card-body pt-1 pb-0">
            <section class="text-right">
              <div class="btn btn-group">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#Modal_tarifas"
                  class="btn btn-sm btn-primary"
                  v-if="
                    $store.getters.can('hidrocarburos.tarifas.edit')
                  "
                  @click="limpiarFormulario"
                >
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </section>
            <section>
              <div class="col-md-12">
                <table
                  class="
                    table table-striped table-bordered table-hover table-sm
                  "
                >
                  <thead class="thead-dark">
                    <tr>
                      <th class="text-center">Estado</th>
                      <th class="text-center">Radicado</th>
                      <th class="text-center">Tarifa</th>
                      <th class="text-center">Tiempo Cargue</th>
                      <th class="text-center">Tiempo Descargue</th>
                      <th class="text-center">Inicio Vigencia</th>
                      <th class="text-center">Fin Vigencia</th>
                      <th class="text-center">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tarifa, index) in tarifas.data" :key="index">
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            tarifa.estado == 1
                              ? 'badge-success'
                              : 'badge-danger'
                          "
                        >
                          {{ tarifa.nEstado }}
                        </span>
                      </td>
                      <td class="text-center">{{ tarifa.radicado }}</td>
                      <td class="text-center">{{ tarifa.tarifa }}</td>
                      <td class="text-center">{{ tarifa.tiempo_cargue_sb }}</td>
                      <td class="text-center">
                        {{ tarifa.tiempo_descargue_sb }}
                      </td>
                      <td class="text-center">
                        {{ tarifa.fecha_inicio_vigencia }}
                      </td>
                      <td class="text-center">
                        {{ tarifa.fecha_fin_vigencia }}
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            data-toggle="modal"
                            data-target="#Modal_tarifas"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can('hidrocarburos.tarifas.edit')
                            "
                            @click="edit(tarifa)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12 form-group">
                <div class="float-left" v-if="tarifas.total">
                  <p>
                    Mostrando del <b>{{ tarifas.from }}</b> al
                    <b>{{ tarifas.to }}</b> de un total:
                    <b>{{ tarifas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="tarifas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="Modal_tarifas"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5 class="modal-title text-white" id="exampleModalLabel">
              Actualizar Tarifa
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!---- Campo Radicado RNDC ---->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="radicado">Radicado RNDC</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="radicado"
                    v-model="form.radicado"
                    :readonly="form.id"
                  />
                </div>
              </div>
              <!---- Input Valor Tarifa ---->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="tarifa">Tarifa</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="tarifa"
                    v-model="form.tarifa"
                    :class="$v.form.tarifa.$invalid ? 'is-invalid' : 'is-valid'"
                    :readonly="form.id"
                  />
                </div>
              </div>
              <!---- Input Tiempo Cargue ---->
              <div class="col-md-2">
                <div class="form-group">
                  <label for="tiempo_cargue_sb">Tiempo de cargue</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="tiempo_cargue_sb"
                    v-model="form.tiempo_cargue_sb"
                    :class="
                      $v.form.tiempo_cargue_sb.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
              <!---- Input Tiempo Descargue ---->
              <div class="col-md-2">
                <div class="form-group">
                  <label for="tiempo_descargue_sb">Tiempo descargue</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="tiempo_descargue_sb"
                    v-model="form.tiempo_descargue_sb"
                    :class="
                      $v.form.tiempo_descargue_sb.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
              </div>
              <!---- Input Fecha Inicio Vigencia ---->
              <div class="col-md-3">
                <div class="form-group">
                  <label for="fecha_inicio_vigencia"
                    >Fecha inicio vigencia</label
                  >
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_inicio_vigencia"
                    v-model="form.fecha_inicio_vigencia"
                    :class="
                      $v.form.fecha_inicio_vigencia.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaFechas"
                  />
                </div>
              </div>
              <!---- Input Fecha Fin Vigencia ---->
              <div class="col-md-3">
                <div class="form-group">
                  <label for="fecha_fin_vigencia">Fecha fin vigencia</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    id="fecha_fin_vigencia"
                    v-model="form.fecha_fin_vigencia"
                    :class="
                      $v.form.fecha_fin_vigencia.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="validaFechas"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-show="
                $store.getters.can('hidrocarburos.tarifas.edit') &&
                !$v.form.$invalid
              "
              @click="save()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required } from "vuelidate/lib/validators";

export default {
  name: "TarifasDetalles",
  components: {
    pagination,
  },
  data() {
    return {
      tarifas: {},      
      form: {
        id: null,
        tarifa_contrato_hidrocarburo_id: this.$parent.id,
        radicado: null,
        tarifa: null,
        tiempo_cargue_sb: null,
        tiempo_descargue_sb: null,
        fecha_inicio_vigencia: null,
        fecha_fin_vigencia: null,
      },
    };
  },
  validations: {
    form: {
      tarifa: {
        required,
      },
      tiempo_cargue_sb: {
        required,
      },
      tiempo_descargue_sb: {
        required,
      },
      fecha_inicio_vigencia: {
        required,
      },
      fecha_fin_vigencia: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.tarifas = {};
      axios
        .get("/api/hidrocarburos/detalle_tarifas?page=" + page, {
          params: {
            tarifa_contrato_hidrocarburo_id: this.$parent.id,
          },
        })
        .then((response) => {
          this.tarifas = response.data;
        });
    },

    validaFechas() {
      if (this.form.fecha_inicio_vigencia && this.form.fecha_fin_vigencia) {
        const fecha_menor = new Date(this.form.fecha_inicio_vigencia);
        const fecha_mayor = new Date(this.form.fecha_fin_vigencia);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin_vigencia = null;
          this.$swal({
            icon: "error",
            title: `La Fecha inicio vigencia no puede ser mayor a la Fecha fin vigencia...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    limpiarFormulario() {
      this.form = {
        id: null,
        tarifa_contrato_hidrocarburo_id: this.$parent.id,
        radicado: null,
        tarifa: null,
        tiempo_cargue_sb: null,
        tiempo_descargue_sb: null,
        fecha_inicio_vigencia: null,
        fecha_fin_vigencia: null,
      };
    },

    save() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;

        // Se asignan los valores para el formulario a enviar
        let formData = new FormData();
        formData.append("datos", JSON.stringify(this.form));        

        axios({
          method: "POST",
          url: "/api/hidrocarburos/detalle_tarifas",
          data: formData,
        })
          .then((res) => {
            this.cargando = false;
            if (res.data.status == 0) {
              this.$swal({
                icon: "error",
                title: res.data.msg,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.$refs.closeModal.click();
              this.limpiarFormulario();              
              this.$swal({
                icon: "success",
                title: "La detalle de la tarifa se guardó exitosamente...!",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.getIndex();
              this.cargando = true;
            }
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title:
                "Ha ocurrido un error, por favor intente la acción nuevamente." +
                e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    edit(tarifa) {
      this.limpiarFormulario(); 
      this.form = {
        id: tarifa.id,
        tarifa_contrato_hidrocarburo_id: this.$parent.id,
        radicado: tarifa.radicado,
        tarifa: tarifa.tarifa,
        tiempo_cargue_sb: tarifa.tiempo_cargue_sb,
        tiempo_descargue_sb: tarifa.tiempo_descargue_sb,
        fecha_inicio_vigencia: tarifa.fecha_inicio_vigencia,
        fecha_fin_vigencia: tarifa.fecha_fin_vigencia,
        estado: 1,
      };
    },

  },
  mounted() {
    this.getIndex();
  },
};
</script>
